import React from "react";
import { NFTActions } from "./Actions";
import { nftContext } from "./Context";

const useNFT = () => {
  const { nftState, dispatch } = React.useContext(nftContext);

  const addFav = (payload) => {
    dispatch({ type: NFTActions.ADD_FAV, payload });
  };

  const removeFav = () => {
    dispatch({ type: NFTActions.REMOVE_FAV });
  };
  const setNftCreateFormData = (payload) => {
    dispatch({ type: NFTActions.SET_CREATE_NFT_DETAILS, payload });
  };
  const setNFTCatagory = (payload) => {
    dispatch({ type: NFTActions.SET_CATAGORY_NFT, payload });
  };
  const updateTopRated = (payload) => {
    dispatch({
      type: NFTActions.SET_TOP_RATED_NFT_DATA,
      payload,
    });
  };
  const updateGalleryData = (payload) => {
    dispatch({
      type: NFTActions.SET_GALLERY_NFT_DATA,
      payload,
    });
  };
  const updateExplore = (payload) => {
    dispatch({
      type: NFTActions.SET_EXPLORE_NFT_DATA,
      payload,
    });
  };
  const viewMoreExplore = (payload) => {
    dispatch({
      type: NFTActions.ADD_NFT_DATA_EXPLORE,
      payload,
    });
  };
  const createdItemes = (payload) => {
    dispatch({
      type: NFTActions.SET_CREATED_NFT,
      payload,
    });
  };
  const collectedItemes = (payload) => {
    dispatch({
      type: NFTActions.SET_COLLECTED_NFT,
      payload,
    });
  };
  const savedItemes = (payload) => {
    dispatch({
      type: NFTActions.SET_SAVE_ITEM_NFT,
      payload,
    });
  };
  const soldItemes = (payload) => {
    dispatch({
      type: NFTActions.SET_SOLD_NFT,
      payload,
    });
  };
  const salesdItemes = (payload) => {
    dispatch({
      type: NFTActions.SET_SALES_NFT,
      payload,
    });
  };
  const galleryItemes = (payload) => {
    dispatch({
      type: NFTActions.SET_GALLERY_NFT,
      payload,
    });
  };
  const giveawayItemes = (payload) => {
    dispatch({
      type: NFTActions.SET_GIVEAWAY_NFT,
      payload,
    });
  };
  const getNftDetailsData = (payload) => {
    dispatch({
      type: NFTActions.GET_NFT,
      payload,
    });
  };
  const clearNftDetailData = (payload) => {
    dispatch({
      type: NFTActions.CLEAR_NFT_DETAIL,
      payload,
    });
  }
  const getToprated = (payload) => {
    dispatch({
      type: NFTActions.SET_TOP_RATED_NFT_DATA,
      payload,
    });
  };
  const getGalleryRecords = (payload) => {
    dispatch({
      type: NFTActions.SET_GALLERY_NFT_DATA,
      payload,
    });
  };
  const getGiveawayRecords = (payload) => {
    dispatch({
      type: NFTActions.SET_GIVEAWAY_NFT_DATA,
      payload,
    });
  };
  const getExploreData = (payload) => {
    dispatch({
      type: NFTActions.SET_EXPLORE_NFT_DATA,
      payload,
    });
  };

  const savedAIItemes = (payload) => {
    dispatch({
      type: NFTActions.SET_SAVED_AI_NFT,
      payload,
    });
  };
  const savedAIcardListInital = (payload) => {
    dispatch({ type: NFTActions.SET_SAVED_AI_NFT_INITIAL, payload });
  };
  const setInitalTimeSavedAIPagination = () => {
    dispatch({ type: NFTActions.SET_PAGINATION_SAVED_AI_INTIAL });
  };

  /* published ai artwork */
  const publishedAIItemes = (payload) => {
    dispatch({
      type: NFTActions.SET_PUBLISHED_AI_NFT,
      payload,
    });
  };
  const publishedAIcardListInital = (payload) => {
    dispatch({ type: NFTActions.SET_PUBLISHED_AI_NFT_INITIAL, payload });
  };
  const setInitalTimePublishedAIPagination = () => {
    dispatch({ type: NFTActions.SET_PAGINATION_PUBLISHED_AI_INTIAL });
  };
  /*------------------------------- */

  const clrNftFormData = (payload) => {
    dispatch({ type: NFTActions.CLR_NFT_DETAILS });
  };
  const CreatcardListInital = (payload) => {
    dispatch({ type: NFTActions.SET_CREATED_NFT_INITIAL, payload });
  };
  const collectioncardListInital = (payload) => {
    dispatch({ type: NFTActions.SET_COLLECTED_NFT_INITIAL, payload });
  };
  const savecardListInital = (payload) => {
    dispatch({ type: NFTActions.SET_SAVE_ITEM_NFT_INITIAL, payload });
  };
  const soldcardListInital = (payload) => {
    dispatch({ type: NFTActions.SET_SOLD_NFT_INITIAL, payload });
  };
  const salsescardListInital = (payload) => {
    dispatch({ type: NFTActions.SET_SALES_NFT_INITIAL, payload });
  };
  const galleryListInital = (payload) => {
    dispatch({ type: NFTActions.SET_GALLERY_INITIAL, payload });
  };
  const giveawayListInital = (payload) => {
    dispatch({ type: NFTActions.SET_GIVEAWAY_INITIAL, payload });
  };
  const updateNftDetsilsinList = (payload) => {
    dispatch({ type: NFTActions.UPDATE_NFT_DETAILS, payload });
  };
  const headerDataSet = (payload) => {
    dispatch({ type: NFTActions.SET_HEADER_DATA, payload });
  };
  const handleDeleteDataupdate = (payload) => {
    dispatch({ type: NFTActions.DELETE_NFT_DATA, payload });
  };
  const setSearchData = (payload) => {
    dispatch({ type: NFTActions.SET_SEARCH_NFT_DATA, payload });
  };
  const setInitalTimeCreatedPagination = () => {
    dispatch({ type: NFTActions.SET_PAGINATION_CREATED_INTIAL });
  };
  const setInitalTimeCollectedPagination = () => {
    dispatch({ type: NFTActions.SET_PAGINATION_COLLECTED_INTIAL });
  };
  const setInitalTimeSaveItemPagination = () => {
    dispatch({ type: NFTActions.SET_PAGINATION_SAVE_ITEM_NFT_INTIAL });
  };
  const setInitalTimeSoldNftPagination = () => {
    dispatch({ type: NFTActions.SET_PAGINATION_SOLD_NFT_INTIAL });
  };
  const setInitalTimeSlaesPagination = () => {
    dispatch({ type: NFTActions.SET_PAGINATION_SALES_NFT_INTIAL });
  };
  const setInitalTimeGalleryPagination = () => {
    dispatch({ type: NFTActions.SET_PAGINATION_GALLERY_INTIAL });
  };
  const setInitalTimeGiveawayPagination = () => {
    dispatch({ type: NFTActions.SET_PAGINATION_GIVEAWAY_INTIAL });
  };

  const getTopCollectionData = (payload) => {
    dispatch({
      type: NFTActions.SET_TOP_COLLECTION_NFT_DATA,
      payload,
    });
  };

  const updateTopCollectionData = (payload) => {
    dispatch({ type: NFTActions.UPDATE_TOP_COLLECTION_DATA, payload });
  }

  const getCartListData = (payload) => {
    dispatch({
      type: NFTActions.SET_CART_LIST_DATA,
      payload,
    });
  };

  const updateCartListData = (payload) => {
    dispatch({ type: NFTActions.UPDATE_CART_LIST_DATA, payload });
  }

  const updateNftData = (payload) => {
    dispatch({ type: NFTActions.UPDATE_NFT_DATA, payload });
  }

  return {
    nftState,
    setSearchData,
    headerDataSet,
    handleDeleteDataupdate,
    getNftDetailsData,
    updateNftDetsilsinList,
    salsescardListInital,
    savecardListInital,
    soldcardListInital,
    CreatcardListInital,
    collectioncardListInital,
    galleryListInital,
    getExploreData,
    getToprated,
    getGalleryRecords,
    getGiveawayRecords,
    updateTopRated,
    updateGalleryData,
    savedItemes,
    salesdItemes,
    soldItemes,
    galleryItemes,
    collectedItemes,
    createdItemes,
    updateExplore,
    viewMoreExplore,
    addFav,
    removeFav,
    setNFTCatagory,
    setNftCreateFormData,
    clrNftFormData,
    setInitalTimeCreatedPagination,
    setInitalTimeCollectedPagination,
    setInitalTimeSaveItemPagination,
    setInitalTimeSoldNftPagination,
    setInitalTimeSlaesPagination,
    setInitalTimeGalleryPagination,

    giveawayItemes,
    giveawayListInital,
    setInitalTimeGiveawayPagination,
    clearNftDetailData,

    savedAIItemes,
    savedAIcardListInital,
    setInitalTimeSavedAIPagination,

    publishedAIItemes,
    publishedAIcardListInital,
    setInitalTimePublishedAIPagination,

    getTopCollectionData,
    updateTopCollectionData,

    getCartListData,
    updateCartListData,
    updateNftData
  };
};

export default useNFT;
