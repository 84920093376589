import React from "react";
import { Col } from "react-bootstrap";
import Link from "next/link";
import styles from "./styles.module.scss";

const MainTitle = ({ className, title, renderComponent }, props) => {
  // const addButtons = () => {
  //   if (props.viewAllRef) {
  //     return (
  //       <>
  //         <div className={`${styles.view_all}`}>
  //           <Link href={props.viewAllRef}>
  //             <a>
  //               View More
  //               <span className={styles.arror_right}>
  //                 <svg
  //                   width="8"
  //                   height="14"
  //                   viewBox="0 0 8 14"
  //                   fill="none"
  //                   xmlns="http://www.w3.org/2000/svg"
  //                 >
  //                   <path
  //                     d="M1 13L7 7L1 1"
  //                     stroke="#666666"
  //                     strokeWidth="2"
  //                     strokeLinecap="round"
  //                     strokeLinejoin="round"
  //                   />
  //                 </svg>
  //               </span>
  //             </a>
  //           </Link>
  //         </div>
  //       </>
  //     );
  //   } else if (props.sliderBtns) {
  //     return (
  //       <>
  //         <div className={styles.slider_btns}>
  //           <span className={styles.arrow_left}>
  //             <svg
  //               width="8"
  //               height="14"
  //               viewBox="0 0 8 14"
  //               fill="none"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 d="M7 13L1 7L7 1"
  //                 stroke="#666666"
  //                 strokeWidth="2"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //             </svg>
  //           </span>
  //           <span className={styles.arrow_right}>
  //             <svg
  //               width="8"
  //               height="14"
  //               viewBox="0 0 8 14"
  //               fill="none"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 d="M1 13L7 7L1 1"
  //                 stroke="#666666"
  //                 strokeWidth="2"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //             </svg>
  //           </span>
  //         </div>
  //       </>
  //     );
  //   }
  //   return <></>;
  // };

  // const handleProps = () => {
  //   if (props.children) return props.children;
  //   return addButtons;
  // };

  return (
    <div className={`${styles.section_header} ${className}`}>
      <h1 className={styles.title}>{title ? title : ""}</h1>
      {renderComponent ? (
        <div className={styles.component}>{renderComponent}</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MainTitle;
