import React from 'react'

const Section = props => {
    return (
        <section
            className={`${(props.className) ? (props.className) : ''}`}
        >
            {props.children}
        </section>
    )
}

export default Section
